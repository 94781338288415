import { addPropertyControls, ControlType } from "framer"
import React from "react"
import { useState, useEffect } from "react"
import { motion } from "framer-motion"

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function SocialShareButtons(props) {
    // Config Properties
    const {
        flexDirection,
        facebook,
        x,
        linkedin,
        shareText,
        iconProperties,
        backgroundType,
    } = props

    // Single Properpties
    const {
        title,
        tint,
        style,
        borderWidth,
        borderColor,
        shareCurrentUrl,
        urlToShare,
        backgroundImage,
        linear,
    } = props

    // Multi properties
    const {
        radius,
        topLeftRadius,
        topRightRadius,
        bottomRightRadius,
        bottomLeftRadius,
        isMixedRadius = false,
    } = props

    const {
        stylePadding,
        topPadding,
        rightPadding,
        bottomPadding,
        leftPadding,
        isMixedPadding = false,
    } = props

    const borderRadius = isMixedRadius
        ? `${topLeftRadius}px ${topRightRadius}px ${bottomRightRadius}px ${bottomLeftRadius}px`
        : `${radius}px`

    const padding = isMixedPadding
        ? `${topPadding}px ${rightPadding}px ${bottomPadding}px ${leftPadding}px`
        : `${stylePadding}px`

    let background
    if (backgroundType == "Solid") {
        background = tint
    } else if (backgroundType == "Image") {
        background = `url(${backgroundImage})`
    } else if (backgroundType == "Linear") {
        const { degree, toColor, fromColor, opacityTo, opacityFrom } = linear
        const gradient = `${degree}deg, ${toColor} ${opacityTo}%, ${fromColor} ${opacityFrom}%`
        background = `linear-gradient(${gradient})`
    }

    const shareOnFacebook = (urlToShare) => {
        const shareUrl = urlToShare ? urlToShare : window.location.href
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
            "_blank",
            "height=400,width=400,left=100,top=100,resizable=yes,scrollbars=yes,status=yes"
        )
    }

    const shareOnX = (urlToShare) => {
        const shareUrl = urlToShare ? urlToShare : window.location.href
        const shareLink = encodeURI(shareText + shareUrl)
        const shareWindow = window.open(
            `https://x.com/intent/tweet?text=${shareLink}`,
            "_blank",
            "height=700,width=700,left=0,top=0,resizable=yes,scrollbars=yes,status=yes"
        )
        shareWindow.blur()
        window.focus()
    }

    const shareOnLinkedIn = (urlToShare) => {
        const shareUrl = urlToShare ? urlToShare : window.location.href

        window.open(
            `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`,
            "_blank",
            "height=400,width=400,left=100,top=100,resizable=yes,scrollbars=yes,status=yes"
        )
    }

    let facebookIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={iconProperties.size}
            fill="none"
            viewBox="0 0 24 24"
            v="32"
        >
            <path
                fill={iconProperties.color}
                fontSize={iconProperties.size}
                fill-rule="evenodd"
                d="M12,2 C17.5228403,2 22,6.49603007 22,12.042148 C22,17.1426622 18.2133748,21.3550488 13.3091699,21.999013 L13.299,21.999 L13.2993358,14.2432707 L15.476925,14.2432707 L15.7655715,11.5132627 L13.2993358,11.5132627 L13.3029934,10.1467742 C13.3029934,9.43472725 13.3703545,9.05337521 14.3888162,9.05337521 L15.750301,9.05337521 L15.750301,6.32290806 L13.5722241,6.32290806 C10.9559654,6.32290806 10.0352533,7.64718738 10.0352533,9.87454164 L10.0352533,11.5134769 L8.404355,11.5134769 L8.404355,14.2437298 L10.0352533,14.2437298 L10.0352533,21.8900234 C5.45236562,20.9728053 2,16.9123412 2,12.042148 C2,6.49603007 6.47715974,2 12,2 Z"
            />
        </svg>
    )
    let xIcon = (
        <svg
            viewBox="0 0 24 24"
            height={iconProperties.size}
            fill="none"
            v="32"
        >
            <path
                fill={iconProperties.color}
                fontSize={iconProperties.size}
                d="M17.7511704,3 L20.8179669,3 L14.1179252,10.6577203 L22,21.0781532 L15.8283966,21.0781532 L10.9945766,14.7582163 L5.46358875,21.0781532 L2.39493812,21.0781532 L9.56130348,12.8873592 L2,3 L8.32828072,3 L12.6976313,8.77666528 L17.7511704,3 Z M16.674825,19.2425254 L18.3741714,19.2425254 L7.40490428,4.73921105 L5.58132851,4.73921105 L16.674825,19.2425254 Z"
            />
        </svg>
    )
    let linkedinIcon = (
        <svg viewBox="0 0 24 24" height={iconProperties.size} fill="none">
            <path
                fill={iconProperties.color}
                fontSize={iconProperties.size}
                d="M2,4.20707071 C2,3.56732727 2.22523166,3.03954949 2.67567568,2.62373737 C3.12611969,2.20790606 3.71171815,2 4.43243243,2 C5.14028958,2 5.71299228,2.20470101 6.15057915,2.61414141 C6.60102317,3.03636364 6.82625483,3.58651919 6.82625483,4.26464646 C6.82625483,4.87878788 6.60747104,5.3905596 6.16988417,5.8 C5.71944015,6.22222222 5.12741313,6.43333333 4.39382239,6.43333333 L4.37451737,6.43333333 C3.66666023,6.43333333 3.09395753,6.22222222 2.65637066,5.8 C2.21878378,5.37777778 2,4.84679495 2,4.20707071 Z M2.25096525,21 L2.25096525,8.17979798 L6.53667954,8.17979798 L6.53667954,21 L2.25096525,21 Z M8.91119691,21 L13.1969112,21 L13.1969112,13.8414141 C13.1969112,13.3935899 13.2483977,13.0481354 13.3513514,12.8050505 C13.5315251,12.3700273 13.8050193,12.0021758 14.1718147,11.7015152 C14.53861,11.4008354 14.9987066,11.2505051 15.5521236,11.2505051 C16.9935714,11.2505051 17.7142857,12.2164919 17.7142857,14.1484848 L17.7142857,21 L22,21 L22,13.6494949 C22,11.7558859 21.549556,10.319697 20.6486486,9.34090909 C19.7477413,8.36212121 18.557278,7.87272727 17.0772201,7.87272727 C15.4169884,7.87272727 14.1235521,8.58282828 13.1969112,10.0030303 L13.1969112,10.0414141 L13.1776062,10.0414141 L13.1969112,10.0030303 L13.1969112,8.17979798 L8.91119691,8.17979798 C8.9369305,8.58921919 8.94980695,9.86227677 8.94980695,11.9989899 C8.94980695,14.1356838 8.9369305,17.1360141 8.91119691,21 Z"
            />
        </svg>
    )

    return (
        <motion.div
            style={{
                ...style,
                display: "flex",
                flexDirection: flexDirection,
                gap: iconProperties.gap,
                justifyContent: "center",
                alignItems: "center",
                overflow: "visible",
            }}
        >
            {facebook ? (
                <motion.div
                    style={{
                        borderRadius,
                        padding,
                        background,
                        overflow: "visible",
                        borderWidth: borderWidth,
                        borderStyle: "solid",
                        borderColor: borderColor,
                        display: "flex",
                        alignItems: "center",
                        gap: iconProperties.gap,
                    }}
                    onClick={() => {
                        if (!shareCurrentUrl) {
                            shareOnFacebook(urlToShare)
                        } else {
                            shareOnFacebook(null)
                        }
                    }}
                    whileHover={{ scale: 1.05, cursor: "pointer" }}
                    transition={{ type: "spring", stiffness: 500, damping: 30 }}
                >
                    {facebookIcon}
                </motion.div>
            ) : null}

            {x ? (
                <motion.div
                    style={{
                        borderRadius,
                        padding,
                        background,
                        overflow: "visible",
                        borderWidth: borderWidth,
                        borderStyle: "solid",
                        borderColor: borderColor,
                        display: "flex",
                        alignItems: "center",
                        gap: iconProperties.gap,
                    }}
                    onClick={() => {
                        if (!shareCurrentUrl) {
                            shareOnX(urlToShare)
                        } else {
                            shareOnX(null)
                        }
                    }}
                    whileHover={{ scale: 1.05, cursor: "pointer" }}
                    transition={{ type: "spring", stiffness: 500, damping: 30 }}
                >
                    {xIcon}
                </motion.div>
            ) : null}

            {linkedin ? (
                <motion.div
                    style={{
                        borderRadius,
                        padding,
                        background,
                        overflow: "visible",
                        borderWidth: borderWidth,
                        borderStyle: "solid",
                        borderColor: borderColor,
                        display: "flex",
                        alignItems: "center",
                        gap: iconProperties.gap,
                    }}
                    onClick={() => {
                        if (!shareCurrentUrl) {
                            shareOnLinkedIn(urlToShare)
                        } else {
                            shareOnLinkedIn(null)
                        }
                    }}
                    whileHover={{ scale: 1.05, cursor: "pointer" }}
                    transition={{ type: "spring", stiffness: 500, damping: 30 }}
                >
                    {linkedinIcon}
                </motion.div>
            ) : null}
        </motion.div>
    )
}

addPropertyControls(SocialShareButtons, {
    facebook: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    x: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    linkedin: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    flexDirection: {
        type: ControlType.Enum,
        options: ["row", "column"],
        optionTitles: ["Horizontal", "Vertical"],
        defaultValue: "row",
    },
    shareCurrentUrl: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    urlToShare: {
        type: ControlType.String,
        defaultValue: "https://framer.com",
        hidden(props) {
            return props.shareCurrentUrl != false
        },
    },
    shareText: {
        type: ControlType.String,
        defaultValue: "Share on X: ",
        description: "Share text is only available for X sharing.",
        hidden(props) {
            return props.x === false
        },
    },
    iconProperties: {
        type: ControlType.Object,
        controls: {
            color: { type: ControlType.Color, defaultValue: "#09090b" },
            size: { type: ControlType.Number, defaultValue: 24 },
            gap: { type: ControlType.Number, defaultValue: 15 },
        },
    },
    backgroundType: {
        type: ControlType.Enum,
        options: ["Solid", "Linear", "Image"],
        defaultValue: "Solid",
    },
    backgroundImage: {
        type: ControlType.Image,
        hidden(props) {
            return props.backgroundType != "Image"
        },
    },
    linear: {
        type: ControlType.Object,
        controls: {
            toColor: { type: ControlType.Color, defaultValue: "#242424" },
            opacityTo: { type: ControlType.Number, defaultValue: 0 },
            fromColor: { type: ControlType.Color, defaultValue: "#323232" },
            opacityFrom: { type: ControlType.Number, defaultValue: 100 },
            degree: { type: ControlType.Number, defaultValue: 90, max: 360 },
        },
        hidden(props) {
            return props.backgroundType != "Linear"
        },
    },
    tint: {
        title: "Background Color",
        type: ControlType.Color,
        defaultValue: "#f4f4f5",
        hidden(props) {
            return props.backgroundType != "Solid"
        },
    },
    radius: {
        type: ControlType.FusedNumber,
        title: "Radius",
        defaultValue: 50,
        toggleKey: "isMixedRadius",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "topLeftRadius",
            "topRightRadius",
            "bottomRightRadius",
            "bottomLeftRadius",
        ],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
    },
    borderWidth: {
        type: ControlType.Number,
        defaultValue: 0,
    },
    borderColor: {
        type: ControlType.Color,
        defaultValue: "#222426",
    },
    stylePadding: {
        type: ControlType.FusedNumber,
        title: "Padding",
        defaultValue: 10,
        toggleKey: "isMixedPadding",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "topPadding",
            "rightPadding",
            "bottomPadding",
            "leftPadding",
        ],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
    },
})
